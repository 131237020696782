<template>
  <v-data-table
    :headers="headers.slice(isAdmin ? null : 1)"
    :items="users"
    :search="search"
    :loading="isLoading"
  >
    <template v-slot:item.attributes.groupIds="{ item }">
      <div
        v-for="group in getUserGroup(item.attributes.groupIds)"
        :key="group.id"
      >
        {{ group }}
      </div>
    </template>
    <template v-slot:item.name="{ item }">
      <p class="info--text subtitle-2 font-weight-medium mb-1 ">
        {{ item.attributes.info.name }}
      </p>
    </template>
    <template v-slot:item.email="{ item }">
      <a class="info--text">{{ item.attributes.email }}</a>
    </template>

    <template v-slot:item.attributes.roles[1]="{ item }">
      <user-role-chip :item="item" />
    </template>

    <template v-slot:item.id="{ item }">
      <!-- <BaseTableActions
        module-name="user"
        :item="item"
        :to-show="{
          name: 'user-show',
          params: {
            userId: item.id,
            role: item.attributes.roles[1] || 'supplier'
          }
        }"
        :to-edit="{
          name: 'user-edit',
          params: {
            userId: item.id,
            role: item.attributes.roles[1] || 'supplier'
          }
        }"
      /> -->
      <v-btn small color="error" icon @click="removeFromOrganization(item.id)"
        ><v-icon small>mdi-delete</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
// import { ORGANIZATION_ID } from '@/common/config.js'
import { mapActions, mapGetters } from 'vuex'
import UserRoleChip from './UserRoleChip'
// import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  name: 'UserIndexTable',
  components: {
    UserRoleChip
    // BaseTableActions
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    isCompany: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: true,
      users: [],
      userGroups: [],
      headers: [
        {
          text: this.$tc('userGroup.company', 1),
          sortable: false,
          value: 'attributes.groupIds'
        },
        {
          text: this.$tc('user.name', 1),
          sortable: false,
          value: 'attributes.info.name'
        },
        {
          text: this.$t('user.companyPosition'),
          sortable: false,
          value: 'attributes.info.companyPosition'
        },
        {
          text: this.$t('common.email'),
          sortable: false,
          value: 'attributes.email'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ isAdmin: 'auth/isAdmin' })
  },
  async created() {
    this.users = await this.filterUsers()
    if (this.isAdmin) this.userGroups = await this.filterUserGroups()
    this.isLoading = false
  },

  methods: {
    ...mapActions({ editUserOrganization: 'user/editUserOrganization' }),
    removeFromOrganization(userId) {
      this.editUserOrganization({ id: userId, action: 'remove' })
    },
    async filterUsers() {
      return await this.$store.dispatch('user/filter', {
        // organizationId: ORGANIZATION_ID(),
        roles: 'user,admin'
      })
    },
    async filterUserGroups() {
      return await this.$store.dispatch('userGroup/filter', {
        // organizationId: ORGANIZATION_ID()
      })
    },
    getUserGroup(userGroupIds) {
      let userGroups = []
      for (let i = 0; i < userGroupIds.length; i++) {
        const response = this.userGroups.find(
          group => group.id === userGroupIds[i]
        )
        userGroups.push(response)
      }
      userGroups = userGroups.filter(group => Boolean(group))
      return userGroups.length
        ? userGroups.map(group => group.attributes.name)
        : '-'
    }
  }
}
</script>
