<template>
  <v-chip v-if="role" label small outlined :color="color">{{
    role | titleCase
  }}</v-chip>
</template>

<script>
export default {
  filters: {
    titleCase: function(str) {
      return str
        .split('_')
        .join(' ')
        .toUpperCase()
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    role() {
      return this.item.attributes.roles[1]
    },
    color() {
      if (this.role === 'supplier') return 'green accent-4'
      else if (this.role === 'customer') return 'orange accent-4'
      else if (this.role === 'forest_manager') return 'amber accent-4'
      else return 'grey'
    }
  }
}
</script>

<style lang="scss" scoped></style>
