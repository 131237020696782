<template>
  <base-index-layout :title="$tc('user.contact', 2)">
    <template v-slot:search>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        outlined
        dense
        hide-details
        class="mr-4"
      ></v-text-field
    ></template>
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <users-table :search="search" />
  </base-index-layout>
</template>

<script>
import UsersTable from './components/UserTable'
export default {
  components: {
    UsersTable
  },

  data() {
    return {
      search: ''
    }
  }
}
</script>
